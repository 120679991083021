import React from 'react'
import "../styles/thank-you.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default ({ location }) => {

    return (
        <Layout location={location} cssPageName="thank-you">
            <SEO title="Thank You!" />
            <h1 className="title">Thank You</h1>
            <p>We'll be in touch shortly (usually within the next 48 hours).</p>
        </Layout>
    )
}